import React from "react"
import Heading from "@elements/heading"
import Paragraph from "@elements/paragraph"
import Div from "@components/elements/div"
import Grid from "@components/elements/grid"
import styled from "styled-components"
import Image from "@components/image"
import { graphql, useStaticQuery } from "gatsby"
import { processImages } from "@utils/process-images"
import media from "../../../styles/media"
import PropTypes from "prop-types"

const GalleryWrapper = styled(Grid)`
  background: rgba(112, 126, 250, 10%);

  ${media.sm`
    background: unset;
  `}
`

export const PmFeatureGallery = ({ container = true }) => {
  const data = useStaticQuery(graphql`
    query PmFeatureGallery {
      images: allDirectusFiles(
        filter: {
          directus_id: {
            in: [
              "0c6a6d7c-df60-4129-941a-a89cda58753c"
              "dab08fc5-7208-4382-9a5d-6b92f0140d7b"
              "e4e37ac3-ddfb-4ff8-b8ae-680ff87243d0"
            ]
          }
        }
      ) {
        edges {
          node {
            directus_id
            title
            placeholder
            cdn(quality: 100)
            description
          }
        }
      }
    }
  `)

  const images = processImages(data.images.edges)

  return (
    <Div container={container}>
      <Div background="rgba(112,126,250,10%)" borderRadius="2rem">
        <Grid
          hideOnMd
          columns={"1fr 2fr 1fr"}
          gap="2rem"
          padding="2rem 4.8rem"
          width="100%"
        >
          <Heading as="h4" color="#707EFA">
            Data Team
          </Heading>
          <Heading
            as="h4"
            color="#707EFA"
            letterSpacing="2.8rem"
            textAlign="center"
            margin="0 -2.8rem 0 0"
          >
            &gt;&gt;&gt;&lt;&lt;&lt;
          </Heading>
          <Heading textAlign="right" as="h4" color="#707EFA">
            Business Users
          </Heading>
        </Grid>
        <GalleryWrapper
          columns={"1fr 1fr 1fr"}
          columnsMd="1fr"
          borderRadius="1rem"
          padding="4rem"
          paddingSm="2rem"
          gap="4rem"
          position="relative"
          zIndex={2}
          center
        >
          <Div>
            <Heading color="white" as="h4" margin="0 0 1rem">
              Connect
            </Heading>
            <Image file={images["0c6a6d7c-df60-4129-941a-a89cda58753c"]} />
            <Paragraph
              color="white"
              lineHeight={1.4}
              fontSize="1.1rem"
              fontWeight={600}
              margin="1rem 0 0"
            >
              Synchronize metrics with data in your warehouse or semantic layer
              or create custom metrics from almost any other data source.
            </Paragraph>
          </Div>
          <Div>
            <Heading color="white" as="h4" margin="0 0 1rem">
              Catalog
            </Heading>
            <Image file={images["dab08fc5-7208-4382-9a5d-6b92f0140d7b"]} />
            <Paragraph
              color="white"
              lineHeight={1.4}
              fontSize="1.1rem"
              fontWeight={600}
              margin="1rem 0 0"
            >
              Create a curated metric catalog for your business teams with
              access control and governance features.
            </Paragraph>
          </Div>
          <Div>
            <Heading color="white" as="h4" margin="0 0 1rem">
              Consume
            </Heading>
            <Image file={images["e4e37ac3-ddfb-4ff8-b8ae-680ff87243d0"]} />
            <Paragraph
              color="white"
              lineHeight={1.4}
              fontSize="1.1rem"
              fontWeight={600}
              margin="1rem 0 0"
            >
              Enable self-serve analysis and dashboard creation in just a few
              clicks - or make it even easier with PowerMetrics AI.
            </Paragraph>
          </Div>
        </GalleryWrapper>
      </Div>
    </Div>
  )
}

PmFeatureGallery.propTypes = {
  container: PropTypes.bool,
}
